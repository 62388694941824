import { graphql, useStaticQuery } from 'gatsby';

const data = {
  pl: {
    content:
      '<div><a title="https://urodaizdrowie.pl/ksiazki-polecane-na-dzien-kobiet-ranking?fbclid=IwAR2uFby2UZGb3zCswC06Bl-ocxfjGRMXlZYwy3PYFKK1tLFEGAvtV1SnVwA" href="https://urodaizdrowie.pl/ksiazki-polecane-na-dzien-kobiet-ranking?fbclid=IwAR2uFby2UZGb3zCswC06Bl-ocxfjGRMXlZYwy3PYFKK1tLFEGAvtV1SnVwA" target="_blank" rel="noopener">Książki polecane na dzień kobiet</a></div>\n<div><a title="http://www.jaw.pl/2019/10/jaworznianki-agnieszka-kazmierczyk/" href="http://www.jaw.pl/2019/10/jaworznianki-agnieszka-kazmierczyk/" target="_blank" rel="noopener">Jaworznianki Agnieszka Kaźmierczyk</a></div>\n<div><a title="http://extra.info.pl/index.php/2019/11/16/jaworznianie-z-pasja-agnieszka-kazmierczyk-wydala-druga-ksiazke/" href="http://extra.info.pl/index.php/2019/11/16/jaworznianie-z-pasja-agnieszka-kazmierczyk-wydala-druga-ksiazke/" target="_blank" rel="noopener">Jaworzanie z pasją</a></div>\n<div><a href="https://jaw.pl/2020/12/robie-to-bo-sprawia-mi-to-ogromna-przyjemnosc-o-pisaniu-rozmowa-z-agnieszka-kazmierczyk/">„Robię to, bo sprawia mi to ogromną przyjemność” – o pisaniu rozmowa z Agnieszką Kaźmierczyk</a></div>\n<div></div>\n<div></div>\n<div><a href="http://tylkomagiaslowa.blogspot.com/2019/09/recenzja-przedpremierowa-agnieszka.html" target="_blank" rel="noopener">Tylko Magia Słowa</a></div>\n<div><a href="http://zaczytana.com.pl/blog/2019/11/07/kazmierczyk-agnieszka-topielica-ze-switezi/" target="_blank" rel="noopener">zaczytana.com.pl</a></div>\n<div><a href="http://www.zakladkimadebya.pl/2019/12/topielica-ze-switezi-agnieszka.html" target="_blank" rel="noopener">www.zakladkimadebya.pl</a></div>\n',
  },
  en: {
    content: '',
  },
  gallery: [] as any[],
};

export const useMediaQuery = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query MediaQuery {
        allFile(filter: { relativePath: { glob: "media/*" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `,
  );

  data.gallery = allFile.nodes;

  return data;
};
