import { graphql, useStaticQuery } from 'gatsby';

export const useImageQuery = () => {
  return useStaticQuery(
    graphql`
      query ImageQuery {
        books_bg: file(relativePath: { eq: "books/klatwa.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media_bg: file(relativePath: { eq: "books/epidemia_bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        about_bg: file(relativePath: { eq: "books/epidemia_bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contact_bg: file(relativePath: { eq: "photo2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        error_bg: file(relativePath: { eq: "books/rusalka.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );
};
